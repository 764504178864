import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  const toggleVisibility = () => {
    if (containerRef.current) {
      const scrolled = containerRef.current.scrollTop;
      console.log('Scroll position:', scrolled);
      setIsVisible(scrolled > 300);
    }
  };

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    // Find the scrollable container (tokenomicsWrapper or roadmapWrapper)
    const scrollContainer = document.querySelector('[class*="tokenomicsWrapper"], [class*="roadmapWrapper"]');
    if (scrollContainer) {
      containerRef.current = scrollContainer;
      scrollContainer.addEventListener('scroll', toggleVisibility);
      // Initial check
      toggleVisibility();
      
      return () => {
        scrollContainer.removeEventListener('scroll', toggleVisibility);
      };
    }
  }, []);

  return (
    <button 
      className={`${styles.scrollToTop} ${isVisible ? styles.visible : ''}`} 
      onClick={scrollToTop}
      aria-label="Scroll to top"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4L4 12H9V20H15V12H20L12 4Z" fill="currentColor" />
      </svg>
    </button>
  );
};

export default ScrollToTop; 