import React from "react";
import AboutTextCard from "../AboutTextCard/AboutTextCard";
import ClassDescription from "../ClassDescription/ClassDescription";

const ClassesSection = () => (
  <AboutTextCard id="classes" title={"Classes"} isLeft startIndex={3}>
    <ClassDescription />
  </AboutTextCard>
);

export default ClassesSection;
