import React, { useState } from 'react';
import styles from './styles.module.scss';

const distributionData = [
  {
    name: 'Public Sale',
    value: 30,
    color: '#8b4513',
    description: 'Available to the community through token sale events',
    tokenAmount: '300,000,000'
  },
  {
    name: 'Private Sale',
    value: 5,
    color: '#b59e60',
    description: 'Reserved for early strategic investors',
    tokenAmount: '50,000,000'
  },
  {
    name: 'Play to Earn',
    value: 25,
    color: '#d4b483',
    description: 'Distributed as rewards for gameplay, achievements, and tournaments',
    tokenAmount: '250,000,000'
  },
  {
    name: 'Staking Rewards',
    value: 15,
    color: '#c19a6b',
    description: 'Rewards for token holders who stake their $PATH',
    tokenAmount: '150,000,000'
  },
  {
    name: 'Team',
    value: 15,
    color: '#deb887',
    description: 'Allocated to the core development team',
    tokenAmount: '150,000,000'
  },
  {
    name: 'Ecosystem Fund',
    value: 7,
    color: '#a67b5b',
    description: 'Supporting partnerships and ecosystem expansion',
    tokenAmount: '70,000,000'
  },
  {
    name: 'Advisors',
    value: 3,
    color: '#966841',
    description: 'Allocated to project advisors and strategic consultants',
    tokenAmount: '30,000,000'
  }
];

const TokenDistribution = () => {
  const [hoveredSegment, setHoveredSegment] = useState(null);
  const radius = 180;
  const padding = 40;
  const size = (radius * 2) + (padding * 2);

  const getSegmentPath = (startAngle, endAngle, radius) => {
    const start = polarToCartesian(startAngle, radius);
    const end = polarToCartesian(endAngle, radius);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    return [
      "M", radius + padding, radius + padding,
      "L", start.x + padding, start.y + padding,
      "A", radius, radius, 0, largeArcFlag, 1, end.x + padding, end.y + padding,
      "Z"
    ].join(" ");
  };

  const polarToCartesian = (angle, radius) => {
    const angleInRadians = ((angle - 90) * Math.PI) / 180.0;
    return {
      x: radius + (radius * Math.cos(angleInRadians)),
      y: radius + (radius * Math.sin(angleInRadians))
    };
  };

  let currentAngle = 0;

  return (
    <div className={styles.container}>
      <div className={styles.chartContainer}>
        <svg width="100%" height="100%" viewBox={`0 0 ${size} ${size}`} preserveAspectRatio="xMidYMid meet">
          {distributionData.map((segment) => {
            const startAngle = currentAngle;
            const endAngle = currentAngle + (segment.value * 3.6);
            currentAngle = endAngle;

            return (
              <path
                key={segment.name}
                d={getSegmentPath(startAngle, endAngle, radius)}
                fill={segment.color}
                stroke="#fff"
                strokeWidth="2"
                onMouseEnter={() => setHoveredSegment(segment)}
                onMouseLeave={() => setHoveredSegment(null)}
                className={`${styles.segment} ${hoveredSegment?.name === segment.name ? styles.highlighted : ''}`}
              />
            );
          })}
        </svg>
        <div className={styles.centerText}>
          <span className={styles.total}>100%</span>
          <span className={styles.label}>Total Supply</span>
        </div>
      </div>

      <div className={styles.detailsContainer}>
        <div className={styles.details}>
          <h3>Token Distribution</h3>
          <div className={styles.distributionList}>
            {distributionData.map((segment) => (
              <div 
                key={segment.name}
                className={`${styles.distributionItem} ${hoveredSegment?.name === segment.name ? styles.highlighted : ''}`}
                onMouseEnter={() => setHoveredSegment(segment)}
                onMouseLeave={() => setHoveredSegment(null)}
              >
                <div className={styles.itemHeader}>
                  <div className={styles.colorIndicator} style={{ backgroundColor: segment.color }} />
                  <h4>{segment.name}</h4>
                  <span className={styles.percentage}>{segment.value}%</span>
                </div>
                <div className={styles.tokenAmount}>
                  <span>Amount: </span>
                  <strong>{segment.tokenAmount}</strong>
                </div>
                <p className={styles.description}>{segment.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenDistribution; 