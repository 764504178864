// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RarityText_common__Z2fyJ {
  color: saddlebrown;
}

.RarityText_rare__ViaAb {
  color: gray;
}

.RarityText_elite__Y-8tw {
  color: orange;
}

.RarityText_legendary__gEUhq {
  color: cornflowerblue;
}

.RarityText_divine__f1iAu {
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/components/AboutComponents/RarityText/RarityText.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".common {\n  color: saddlebrown;\n}\n\n.rare {\n  color: gray;\n}\n\n.elite {\n  color: orange;\n}\n\n.legendary {\n  color: cornflowerblue;\n}\n\n.divine {\n  color: green;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"common": `RarityText_common__Z2fyJ`,
	"rare": `RarityText_rare__ViaAb`,
	"elite": `RarityText_elite__Y-8tw`,
	"legendary": `RarityText_legendary__gEUhq`,
	"divine": `RarityText_divine__f1iAu`
};
export default ___CSS_LOADER_EXPORT___;
