import About from "../pages/About/About";
import Landing from "../pages/Landing/Landing";
import paths from "../pages/paths";
import RoadMap from "../pages/RoadMap/RoadMap";
import Tokenomics from "../pages/Tokenomics/Tokenomics";
import Wiki from "../pages/Wiki/Wiki";

const routesConfig = [
  {
    path: paths.index,
    component: Landing,
  },
  {
    path: paths.about,
    component: About,
  },
  {
    path: paths.wiki,
    component: Wiki,
  },
  {
    path: paths.roadmap,
    component: RoadMap,
  },
  {
    path: paths.tokenomics,
    component: Tokenomics,
  },
];

export default routesConfig;
