import React from 'react';

import { DescriptionIcon, LogoTextIcon } from '../../assets/icons';

import BackButton from '../../components/AboutComponents/BackButton/BackButton';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import TokenDistribution from '../../components/TokenDistribution/TokenDistribution';
import styles from './styles.module.scss';

const Tokenomics = () => {
  return (
    <div className={styles.tokenomicsWrapper}>
      <BackButton />
      <ScrollToTop />

      <div className={styles.content}>
        <LogoTextIcon />
        <DescriptionIcon />
        <div className={styles.tokenomicsContainer}>
          <h2 className={styles.sectionTitle}>Our Dual Token System</h2>
          <div className={styles.description}>
            <p>
              The Path features a dual token economy designed to create a sustainable ecosystem that balances gameplay, investment, and governance:
            </p>
            <div className={styles.tokenTypes}>
              <div className={styles.tokenType}>
                <h3>$PATH - Governance & Investment Token</h3>
                <p>
                  $PATH is a TON (The Open Network) Jetton token for The Path universe. Holders can claim rewards through staking, 
                  participate in key governance votes, and have true ownership in the game's ecosystem. $PATH aligns 
                  incentives between players and developers in revolutionary ways.
                </p>
              </div>
              <div className={styles.tokenType}>
                <h3>$WAY - Utility Token</h3>
                <p>
                  $WAY is the in-game utility token used for character creation, upgrades, item purchases, and other 
                  in-game activities. This token circulates within the game economy and is earned through gameplay.
                </p>
              </div>
            </div>
          </div>

          <h2 className={styles.sectionTitle}>$PATH Token</h2>
          <div className={styles.description}>
            <p>
              $PATH tokens are the governance and investment token of The Path ecosystem. Holders will be able to claim rewards by staking their tokens, 
              playing the game, and participating in key governance votes. Players can also earn $PATH when they play various game modes and through user-generated content initiatives.
            </p>
            
            <p>
              <strong>Token Name:</strong> The Path Token<br />
              <strong>Token Symbol:</strong> $PATH<br />
              <strong>Token Standard:</strong> TON Jetton<br />
              <strong>Maximum Supply:</strong> 1,000,000,000 $PATH
            </p>
            
            <p>
              Our goal with $PATH is to align the incentives between the players and the developers in novel ways:
            </p>
            <ul>
              <li>To reward players for engaging with The Path while incentivizing them to hold tokens for additional rewards</li>
              <li>To decentralize the ownership and governance of The Path ecosystem</li>
            </ul>
            
            <p>
              We want The Path to become a game truly owned and operated by the community that plays it. The shift to a Decentralized 
              Autonomous Organization (DAO) will occur gradually over time to ensure sustainable growth.
            </p>
          </div>

          <h2 className={styles.sectionTitle}>$PATH Token Distribution</h2>
          <TokenDistribution />

          <h2 className={styles.sectionTitle}>Public Sale</h2>
          <div className={styles.description}>
            <div className={styles.tokenDetails}>
              <div className={styles.tokenAmount}>
                <span>Token Amount:</span>
                <strong>300,000,000</strong>
              </div>
              <div className={styles.tokenPercentage}>
                <span>% of total supply:</span>
                <strong>30%</strong>
              </div>
              <div className={styles.unlockSchedule}>
                <span>Unlock schedule:</span>
                <strong>Instant unlock</strong>
              </div>
            </div>
            <p>
              The Public Sale allocation represents 30% of the total $PATH supply, making it the largest single allocation. This ensures 
              broad distribution and accessibility to the community. The tokens will be available through various token sale events, 
              allowing community members to participate in The Path's growth from the beginning.
            </p>
          </div>

          <h2 className={styles.sectionTitle}>Private Sale</h2>
          <div className={styles.description}>
            <div className={styles.tokenDetails}>
              <div className={styles.tokenAmount}>
                <span>Token Amount:</span>
                <strong>50,000,000</strong>
              </div>
              <div className={styles.tokenPercentage}>
                <span>% of total supply:</span>
                <strong>5%</strong>
              </div>
              <div className={styles.unlockSchedule}>
                <span>Unlock schedule:</span>
                <strong>Quarterly over 2 years</strong>
              </div>
            </div>
            <p>
              Early strategic investors will receive exclusive access to The Path ecosystem, including early access to game features, 
              special NFT drops, and priority access to future token sales. This allocation ensures strong partnerships with key 
              stakeholders who will help grow and develop The Path ecosystem.
            </p>
            <p>
              20% of tokens will be unlocked at Token Generation Event (TGE), with the remaining 80% unlocked quarterly over 2 years.
            </p>
          </div>

          <h2 className={styles.sectionTitle}>Play to Earn (P2E)</h2>
          <div className={styles.description}>
            <div className={styles.tokenDetails}>
              <div className={styles.tokenAmount}>
                <span>Token Amount:</span>
                <strong>250,000,000</strong>
              </div>
              <div className={styles.tokenPercentage}>
                <span>% of total supply:</span>
                <strong>25%</strong>
              </div>
              <div className={styles.unlockSchedule}>
                <span>Unlock schedule:</span>
                <strong>Gradual unlock over 4.5 years</strong>
              </div>
            </div>
            <p>
              The P2E allocation represents 25% of the total $PATH supply. The goal is to attract a wide range of players to
              The Path ecosystem in search of token rewards and to give ownership to the most active community members.
            </p>
            <p>
              We will reward key behaviors within our ecosystem such as:
            </p>
            <ul>
              <li>Competing in PvP (Player vs Player) battles and winning matches</li>
              <li>Winning tournaments</li>
              <li>Completing PvE (Player vs Environment) dungeons and quests</li>
              <li>Using The Path marketplace</li>
              <li>Creating and upgrading heroes</li>
            </ul>
            <p>
              This allocation can be seen as a marketing budget for The Path ecosystem. When certain game modes and features 
              are ready to be promoted, its P2E allocation could be increased through governance decisions.
            </p>
            <p>
              The $PATH tokens earned through the P2E allocation will initially be open for trading immediately, 
              but the team will monitor the situation closely to decide if a lock-up on rewards is needed.
            </p>
            <h4>Why Play to Earn?</h4>
            <p>
              The success of any game depends on its community. When players invest their time and energy into The Path, they're creating value 
              for everyone. We believe in rewarding this contribution, making gaming not just entertainment, but a meaningful activity that 
              benefits both players and the ecosystem.
            </p>
            <p>
              The Path is an evolving project that's gaining momentum every day. While we're still in our early stages, the potential is immense. 
              $PATH serves as the catalyst for growth, designed to transform The Path from a promising project into a fully matured gaming ecosystem.
            </p>
          </div>

          <h2 className={styles.sectionTitle}>Staking Rewards</h2>
          <div className={styles.description}>
            <div className={styles.tokenDetails}>
              <div className={styles.tokenAmount}>
                <span>Token Amount:</span>
                <strong>150,000,000</strong>
              </div>
              <div className={styles.tokenPercentage}>
                <span>% of total supply:</span>
                <strong>15%</strong>
              </div>
              <div className={styles.unlockSchedule}>
                <span>Unlock schedule:</span>
                <strong>Gradual unlock over ~5.5 years</strong>
              </div>
            </div>
            <p>
              Through staking, we reward community members who demonstrate commitment to The Path's long-term growth. When you stake your $PATH tokens, 
              you'll receive regular rewards through our staking platform. As the ecosystem evolves, stakers will gain governance rights and participate 
              in decisions about the Community Treasury's resource allocation.
            </p>
            <p>
              We're developing The Path Score system to recognize and reward valuable contributions to our ecosystem. This innovative mechanism will 
              enhance rewards for those who actively contribute to The Path's development. For non-playing stakers, we're creating a delegation system 
              that allows them to partner with active community members. This could create exciting opportunities where major token holders collaborate 
              with skilled players to optimize their staking rewards and ecosystem impact.
            </p>
          </div>

          <h2 className={styles.sectionTitle}>Team</h2>
          <div className={styles.description}>
            <div className={styles.tokenDetails}>
              <div className={styles.tokenAmount}>
                <span>Token Amount:</span>
                <strong>150,000,000</strong>
              </div>
              <div className={styles.tokenPercentage}>
                <span>% of total supply:</span>
                <strong>15%</strong>
              </div>
              <div className={styles.unlockSchedule}>
                <span>Unlock schedule:</span>
                <strong>Gradual over 4.5 years</strong>
              </div>
            </div>
            <p>
              The Team allocation represents 15% of the total supply, allocated to the core development team. This allocation ensures 
              long-term alignment between the team and the project's success, with tokens being unlocked gradually over time to maintain 
              sustainable development.
            </p>
          </div>

          <h2 className={styles.sectionTitle}>Ecosystem Fund</h2>
          <div className={styles.description}>
            <div className={styles.tokenDetails}>
              <div className={styles.tokenAmount}>
                <span>Token Amount:</span>
                <strong>70,000,000</strong>
              </div>
              <div className={styles.tokenPercentage}>
                <span>% of total supply:</span>
                <strong>7%</strong>
              </div>
              <div className={styles.unlockSchedule}>
                <span>Unlock schedule:</span>
                <strong>Gradual over 4 years</strong>
              </div>
            </div>
            <p>
              The Ecosystem Fund serves as a strategic resource for The Path's development, managed by our core team to drive community growth 
              and foster valuable ecosystem initiatives. This fund is designed to catalyze meaningful projects and partnerships that enhance 
              The Path's ecosystem.
            </p>
            <p>Our funding framework is built on these core principles:</p>
            <ul>
              <li>Projects must demonstrate clear benefits to The Path's community</li>
              <li>Measurable success metrics and defined objectives for fund allocation</li>
              <li>Development initiatives driven by community needs and feedback</li>
              <li>Strategic partnerships that expand The Path's ecosystem reach</li>
            </ul>
            <p>
              The Ecosystem Fund will transition to community governance through our DAO structure, enabling token holders to participate 
              in funding decisions and shape the future of The Path ecosystem.
            </p>
          </div>

          <h2 className={styles.sectionTitle}>Advisors</h2>
          <div className={styles.description}>
            <div className={styles.tokenDetails}>
              <div className={styles.tokenAmount}>
                <span>Token Amount:</span>
                <strong>30,000,000</strong>
              </div>
              <div className={styles.tokenPercentage}>
                <span>% of total supply:</span>
                <strong>3%</strong>
              </div>
              <div className={styles.unlockSchedule}>
                <span>Unlock schedule:</span>
                <strong>Gradual over 4 years</strong>
              </div>
            </div>
            <p>
              The Advisors allocation represents 3% of the total supply, allocated to project advisors and strategic consultants. 
              These advisors bring valuable expertise and guidance to The Path ecosystem, helping to ensure its long-term success 
              and sustainable growth.
            </p>
            <p>
              We are currently in discussions with potential advisors and will announce them as they join the project.
            </p>
          </div>

          <h2 className={styles.sectionTitle}>$WAY - In-Game Utility Token</h2>
          <div className={styles.description}>
            <p>
              $WAY is the utility token used within The Path game for various gameplay activities. Unlike $PATH, which is 
              primarily a governance and investment token, $WAY circulates within the game economy and is earned through gameplay.
            </p>
            <p>$WAY is used for:</p>
            <ul>
              <li>Creating and upgrading heroes</li>
              <li>Purchasing in-game items and equipment</li>
              <li>Enhancing character abilities</li>
              <li>Entering special game modes and events</li>
              <li>Trading with other players in the in-game economy</li>
            </ul>
            <p>
              $WAY maintains a healthy in-game economy through various sinks and faucets, ensuring a balanced gameplay experience 
              that rewards skilled players while maintaining economic stability.
            </p>
          </div>

          <h2 className={styles.sectionTitle}>Token Economics</h2>
          <div className={styles.description}>
            <p>
              Our dual token model creates a sustainable economy through several key mechanisms:
            </p>
            <ul>
              <li><strong>$PATH Staking:</strong> Encourages long-term holding and community governance</li>
              <li><strong>Token Swapping:</strong> Limited conversion between $PATH and $WAY maintains token utility separation</li>
              <li><strong>Season Passes:</strong> Each new game season requires tokens to access premium content</li>
              <li><strong>NFT (Non-Fungible Token) Integration:</strong> Heroes and items represented as NFTs maintain value and scarcity</li>
            </ul>
            <p>
              This tokenomics model is designed to create a sustainable economy that rewards active players and long-term holders 
              while maintaining token value through deliberate economic mechanisms.
            </p>
          </div>
          <div className={styles.disclaimer}>
            <p>* Please note: All token allocations, percentages, and economic mechanisms presented here are subject to change based on market conditions, community feedback, and regulatory requirements.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics; 