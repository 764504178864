import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { desktopBackground, mobileBackground } from "../utils/enums";
import paths from "../pages/paths";

const useLanding = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  useEffect(() => {
    const img = new Image();

    const loadImage = new Promise((resolve) => {
      img.src = isMobile ? mobileBackground : desktopBackground;
      img.onload = () => resolve();
    });

    loadImage.then(() => {
      setIsContentLoaded(true);
    });
  }, [isMobile]);

  const handleGoToAbout = () => {
    navigate(paths.about);
  };

  const handlePlayButtonClick = () => {
    window.open("https://t.me/ThePathGameBot/discover");
  };

  return {
    isContentLoaded,
    handleGoToAbout,
    handlePlayButtonClick,
  };
};

export default useLanding;
