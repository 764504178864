import styles from "./ClassDescription.module.scss";
import { ClassImages } from "../../../utils/enums";

export const classData = [
  {
    description:
      "Enhances musical and supportive abilities, increasing the chance of obtaining and boosting stats related to magic and support.",
    src: ClassImages.BARD,
    animationClass: styles.newtonBallLeft,
  },
  {
    description:
      "Specializes in stealth and precision, improving the odds of gaining stats that enhance agility and critical hit damage.",
    src: ClassImages.ASSASSIN,
    animationClass: styles.newtonBallInner,
  },
  {
    description:
      "Master of elemental forces, increasing the likelihood of obtaining stats related to magical power and spell efficiency.",
    src: ClassImages.MAG,
    animationClass: styles.newtonBallInner,
  },
  {
    description:
      "A powerhouse in combat, boosting the chances of receiving stats that enhance physical strength and defense.",
    src: ClassImages.WARRIOR,
    animationClass: styles.newtonBallInner,
  },
  {
    description:
      "The shield of the team, improving the odds of acquiring stats that increase durability and protective abilities.",
    src: ClassImages.DEFENDER,
    animationClass: styles.newtonBallRight,
  },
];
