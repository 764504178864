import React from "react";
import BackButton from "../BackButton/BackButton";
import { DescriptionIcon, LogoTextIcon } from "../../../assets/icons";

const AboutTitle = () => {
  return (
    <>
      <BackButton />
      <div style={{ marginTop: 24 }}>
        <LogoTextIcon />
      </div>
      <div>
        <DescriptionIcon />
      </div>
    </>
  );
};

export default AboutTitle;
