import React from "react";
import { LogoTextIcon } from "../../assets/icons";
import Loader from "../../components/Loader/Loader";
import PlayButton from "../../components/PlayButton/PlayButton";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import useLanding from "../../hooks/useLanding";
import styles from "./styles.module.scss";

const Landing = () => {
  const { isContentLoaded, handleGoToAbout, handlePlayButtonClick, handleGoToRoadMap, handleGoToTokenomics } =
    useLanding();

  if (!isContentLoaded) {
    return <Loader />;
  }

  return (
    <div className={styles.landingWrapper}>
      <LogoTextIcon />
      <div className={styles.column}>
        <PlayButton onClick={handlePlayButtonClick} title="Play" />
        <PlayButton onClick={handleGoToRoadMap} title="Roadmap" />
        <PlayButton onClick={handleGoToTokenomics} title="Tokenomics" />
        <PlayButton onClick={handleGoToAbout} title="About" />
      </div>
      <SocialLinks />
    </div>
  );
};

export default Landing;
