import React from "react";
import styles from "./styles.module.scss";
import PlayButton from "../../components/PlayButton/PlayButton";
import Loader from "../../components/Loader/Loader";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import { LogoTextIcon } from "../../assets/icons";
import useLanding from "../../hooks/useLanding";

const Landing = () => {
  const { isContentLoaded, handleGoToAbout, handlePlayButtonClick } =
    useLanding();

  if (!isContentLoaded) {
    return <Loader />;
  }

  return (
    <div className={styles.landingWrapper}>
      <LogoTextIcon />
      <div className={styles.column}>
        <PlayButton onClick={handlePlayButtonClick} />
        <PlayButton onClick={handleGoToAbout} isAbout />
      </div>
      <SocialLinks />
    </div>
  );
};

export default Landing;
