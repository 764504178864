import React from "react";
import CharacterAttributes from "../CharacterAttributes/CharacterAttributes";
import AboutTextCard from "../AboutTextCard/AboutTextCard";

const AttributesSection = () => (
  <AboutTextCard id="attributes" title={"Character Attributes"} startIndex={4}>
    <CharacterAttributes />
  </AboutTextCard>
);

export default AttributesSection;
