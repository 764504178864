// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavbarItem_navbarItem__s8u5r {
  display: flex;
  align-items: center;
  gap: 16px;
}

.NavbarItem_left__J-WFA {
  justify-content: flex-start;
}

.NavbarItem_right__yF-vw {
  justify-content: flex-end;
}

.NavbarItem_navbarIcon__sU3Jm {
  width: 50px;
  height: 50px;
  object-fit: contain;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 5px;
  animation: NavbarItem_shake__MJyNp 2s infinite;
}

@keyframes NavbarItem_shake__MJyNp {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(5deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AboutComponents/NavbarItem/NavbarItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,wCAAA;EACA,kBAAA;EACA,YAAA;EACA,8CAAA;AACF;;AAEA;EACE;IAAW,wBAAA;EAEX;EADA;IAAM,wBAAA;EAIN;EAHA;IAAM,uBAAA;EAMN;EALA;IAAM,uBAAA;EAQN;AACF","sourcesContent":[".navbarItem {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.left {\n  justify-content: flex-start;\n}\n\n.right {\n  justify-content: flex-end;\n}\n\n.navbarIcon {\n  width: 50px;\n  height: 50px;\n  object-fit: contain;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  border-radius: 8px;\n  padding: 5px;\n  animation: shake 2s infinite;\n}\n\n@keyframes shake {\n  0%, 100% { transform: translateX(0); }\n  25% { transform: rotate(-5deg); }\n  50% { transform: rotate(0deg); }\n  75% { transform: rotate(5deg); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbarItem": `NavbarItem_navbarItem__s8u5r`,
	"left": `NavbarItem_left__J-WFA`,
	"right": `NavbarItem_right__yF-vw`,
	"navbarIcon": `NavbarItem_navbarIcon__sU3Jm`,
	"shake": `NavbarItem_shake__MJyNp`
};
export default ___CSS_LOADER_EXPORT___;
