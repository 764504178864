import React from "react";
import NavbarItem from "../NavbarItem/NavbarItem";
import styles from "./GameInterfaceOverview.module.scss";
import { NavbarImages } from "../../../utils/enums";

const GameInterfaceOverview = () => (
  <div className={styles.navBarSection}>
    <NavbarItem icon={NavbarImages.HEROES} align="right">
      Heroes: Discover and manage your collection of unique heroes. Equip them
      with powerful items, level them up, and prepare them for quests, PvP
      battles, and seasonal challenges.
    </NavbarItem>
    <NavbarItem icon={NavbarImages.QUESTS} align="right">
      Quests: Send your heroes on missions to gain experience, treasures, and
      other rewards. These quests are crucial for character growth and
      development.
    </NavbarItem>
    <NavbarItem icon={NavbarImages.HOME} align="left">
      Main Page: Access all major game sections through a simple interface and
      stay updated with banners showcasing giveaways and special events.
    </NavbarItem>
    <NavbarItem icon={NavbarImages.SHOP} align="left">
      Shop: Purchase in-game products using Telegram stars, cryptocurrency, or
      in-game currency. A variety of treasures, items, and upgrades are
      available to enhance your gameplay.
    </NavbarItem>
    <NavbarItem icon={NavbarImages.RATING} align="right">
      Leaderboard: Check out the top 100 most powerful players. Compete with
      others and strive to see your name climb the ranks.
    </NavbarItem>
  </div>
);

export default GameInterfaceOverview;
