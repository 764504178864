import React, { useRef } from "react";
import useImageSwitcher from "../../../hooks/useImageSwitcher";
import ImageComponent from "../../Image/Image";
import styles from "./styles.module.scss";

const AboutTextCard = ({
  children,
  isLeft = false,
  title = "",
  id,
  startIndex = 0,
}) => {
  const cardRef = useRef(null);
  const imgRef = useRef(null);

  const { currentImage, visible } = useImageSwitcher(
    startIndex,
    id,
    cardRef,
    imgRef,
  );

  return (
    <div id={id} className={styles.row}>
      {isLeft && (
        <ImageComponent
          ref={imgRef}
          alt={title}
          mainSrc={currentImage.main}
          wrapperClassName={`${styles.firstImage} ${visible ? styles.visible : styles.hidden} ${styles.leftIcon}`}
          width="200px"
          height="300px"
        />
      )}
      <div
        ref={cardRef}
        className={`${styles.papyrusWrapper} ${styles.hidden}`}
      >
        <div className={styles.papyrusContent}>
          <div className={styles.border}>
            <h3>
              <strong>{title}</strong>
            </h3>
            {children}
          </div>
        </div>
      </div>
      {!isLeft && (
        <ImageComponent
          ref={imgRef}
          alt={title}
          mainSrc={currentImage.main}
          previewSrc={currentImage.preview}
          wrapperClassName={`${styles.firstImage} ${visible ? styles.visible : styles.hidden}`}
          width="200px"
          height="300px"
        />
      )}
    </div>
  );
};

export default AboutTextCard;
