import React from "react";
import styles from "./ClassDescription.module.scss";
import { classData } from "./constants";
import ImageComponent from "../../Image/Image";

const ClassIcon = ({ src, alt, animationClass }) => (
  <ImageComponent
    mainSrc={src.MAIN}
    alt={alt}
    wrapperClassName={`${styles.classesIcon} ${animationClass}`}
  />
);

const ClassDescription = () => (
  <div className={styles.classesText}>
    <div className={styles.classInfo}>
      <p>
        Our game features five unique classes, each with its own traits. Every
        class gets a bonus to certain attributes, enhancing their performance in
        various situations. Additionally, each class has a better chance of
        gaining abilities specific to that class. The hero's class is determined
        when they are acquired.
      </p>
      <div className={styles.classDescription}>
        {classData.map((classItem, index) => (
          <ClassIcon
            key={index}
            src={classItem.src}
            alt={`class icon ${index + 1}`}
            animationClass={classItem.animationClass}
          />
        ))}
      </div>
    </div>
  </div>
);

export default ClassDescription;
