// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CharacterAttributes_attributesText__AgEXS {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4a3b2c;
}

.CharacterAttributes_attributeInfo__oPjfh {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.CharacterAttributes_attributeItem__oVUN1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  animation: CharacterAttributes_fadeIn__cMhXL 1s ease-in-out;
}

.CharacterAttributes_attributeItem__oVUN1 strong {
  font-size: 1.1em;
}

.CharacterAttributes_iconWrapper__0Z7al {
  margin-right: 10px;
}

.CharacterAttributes_icon__\\+BJkE {
  width: 30px;
  height: 30px;
  object-fit: contain;
  animation: CharacterAttributes_bounce__xErYn 1.5s infinite;
}

.CharacterAttributes_textWrapper__SXm6t {
  display: flex;
  flex-direction: column;
}

@keyframes CharacterAttributes_fadeIn__cMhXL {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes CharacterAttributes_bounce__xErYn {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AboutComponents/CharacterAttributes/CharacterAttributes.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,2DAAA;EACA,SAAA;EACA,gBAAA;AACF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2DAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,0DAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE;IACE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF;AAGA;EACE;IACE,wBAAA;EADF;EAGA;IACE,2BAAA;EADF;AACF","sourcesContent":[".attributesText {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: #4a3b2c;\n}\n\n.attributeInfo {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));\n  gap: 16px;\n  margin-top: 16px;\n}\n\n\n.attributeItem {\n  display: flex;\n  align-items: center;\n  margin-bottom: 10px;\n  animation: fadeIn 1s ease-in-out;\n}\n\n.attributeItem strong {\n  font-size: 1.1em;\n}\n\n.iconWrapper {\n  margin-right: 10px;\n}\n\n.icon {\n  width: 30px;\n  height: 30px;\n  object-fit: contain;\n  animation: bounce 1.5s infinite;\n}\n\n.textWrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes bounce {\n  0%, 100% {\n    transform: translateY(0);\n  }\n  50% {\n    transform: translateY(-5px);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attributesText": `CharacterAttributes_attributesText__AgEXS`,
	"attributeInfo": `CharacterAttributes_attributeInfo__oPjfh`,
	"attributeItem": `CharacterAttributes_attributeItem__oVUN1`,
	"fadeIn": `CharacterAttributes_fadeIn__cMhXL`,
	"iconWrapper": `CharacterAttributes_iconWrapper__0Z7al`,
	"icon": `CharacterAttributes_icon__+BJkE`,
	"bounce": `CharacterAttributes_bounce__xErYn`,
	"textWrapper": `CharacterAttributes_textWrapper__SXm6t`
};
export default ___CSS_LOADER_EXPORT___;
