import React from "react";
import styles from "./NavbarItem.module.scss";
import ImageComponent from "../../Image/Image";

const NavbarItem = ({ title, icon, align, children }) => (
  <div
    className={`${styles.navbarItem} ${align === "left" ? styles.left : styles.right}`}
  >
    {align === "left" && (
      <ImageComponent
        mainSrc={icon.MAIN}
        alt={`${title} icon`}
        imageClassName={styles.navbarIcon}
        width="50px"
        height="50px"
      />
    )}
    <span>{children}</span>
    {align === "right" && (
      <ImageComponent
        mainSrc={icon.MAIN}
        alt={`${title} icon`}
        imageClassName={styles.navbarIcon}
        width="50px"
        height="50px"
      />
    )}
  </div>
);

export default NavbarItem;
