import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { apiUrl } from "../../utils/enums";
import LootTable from "../../components/LootTable/LootTable";

const Wiki = () => {
  const [data, setData] = useState([]);
  const [tablesData, setTablesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedTableIndex, setExpandedTableIndex] = useState(null);

  const url = `${apiUrl}/market_items/receive_probability`;
  const tablesUrl = `${apiUrl}/loot_tables/chances`;

  useEffect(() => {
    Promise.allSettled([
      axios
        .get(url)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching market items:", error);
        }),
      axios
        .get(tablesUrl)
        .then((response) => {
          setTablesData(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching loot tables:", error);
        }),
    ]).then(() => {
      setIsLoading(false);
    });
  }, [tablesUrl, url]);

  const handleToggle = (index) => {
    setExpandedTableIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !tablesData.length && !tablesData.length) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {data.map((item) => {
        if (!item.receive_probability.length) return null;
        return (
          <div key={item.id} className={styles.itemContainer}>
            <h2 className={styles.text}>{item.title}</h2>
            <p className={styles.text}>{item.description}</p>
            <img
              src={item.image_url}
              alt={item.title}
              className={styles.image}
            />
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Rarity</th>
                  <th>Drop Chance</th>
                  <th>Stat Range</th>
                </tr>
              </thead>
              <tbody>
                {item.receive_probability.map((probability, index) => (
                  <tr key={index}>
                    <td>{probability.name}</td>
                    <td>{probability.receive_probability}%</td>
                    <td>
                      {probability.min} - {probability.max}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}

      {!!tablesData.length && (
        <>
          <h2 className={styles.text}>Loot Tables</h2>
          <div className={styles.lootTable}>
            {tablesData.map((table, index) => (
              <LootTable
                key={index}
                table={table}
                isExpanded={expandedTableIndex === index}
                onToggle={() => handleToggle(index)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Wiki;
