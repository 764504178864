// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_imagesWrapper__IZO16 {
  height: 170px;
  min-height: 170px;
  position: relative;
}

.styles_image__7tevc {
  position: absolute;
  width: 100px; /* Adjust as needed */
  height: 150px; /* Adjust as needed */
  transform-origin: center bottom;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.styles_image__7tevc:nth-child(1) {
  transform: translateX(-50%) rotate(-40deg);
}
.styles_image__7tevc:nth-child(2) {
  transform: translateX(-50%) rotate(-20deg);
}
.styles_image__7tevc:nth-child(3) {
  transform: translateX(-50%) rotate(0deg);
}
.styles_image__7tevc:nth-child(4) {
  transform: translateX(-50%) rotate(20deg);
}
.styles_image__7tevc:nth-child(5) {
  transform: translateX(-50%) rotate(40deg);
}

.styles_imgWrap__wIWgN {
  width: 100%;
  height: 100%;
  animation: styles_breathe__6Qt7P 3s ease-in-out infinite;
}
.styles_imgWrap__wIWgN > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@keyframes styles_breathe__6Qt7P {
  0%, 100% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(1.05) translateY(-5px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AboutComponents/Cards/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA,EAAA,qBAAA;EACA,aAAA,EAAA,qBAAA;EACA,+BAAA;EACA,MAAA;EACA,SAAA;EACA,2BAAA;AACF;AACE;EACE,0CAAA;AACJ;AACE;EACE,0CAAA;AACJ;AACE;EACE,wCAAA;AACJ;AACE;EACE,yCAAA;AACJ;AACE;EACE,yCAAA;AACJ;;AAGA;EACE,WAAA;EACA,YAAA;EACA,wDAAA;AAAF;AAEE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAAJ;;AAIA;EACE;IACE,iCAAA;EADF;EAGA;IACE,uCAAA;EADF;AACF","sourcesContent":[".imagesWrapper {\n  height: 170px;\n  min-height: 170px;\n  position: relative;\n}\n\n.image {\n  position: absolute;\n  width: 100px; /* Adjust as needed */\n  height: 150px; /* Adjust as needed */\n  transform-origin: center bottom;\n  top: 0;\n  left: 50%;\n  transform: translateX(-50%);\n\n  &:nth-child(1) {\n    transform: translateX(-50%) rotate(-40deg);\n  }\n  &:nth-child(2) {\n    transform: translateX(-50%) rotate(-20deg);\n  }\n  &:nth-child(3) {\n    transform: translateX(-50%) rotate(0deg);\n  }\n  &:nth-child(4) {\n    transform: translateX(-50%) rotate(20deg);\n  }\n  &:nth-child(5) {\n    transform: translateX(-50%) rotate(40deg);\n  }\n}\n\n.imgWrap {\n  width: 100%;\n  height: 100%;\n  animation: breathe 3s ease-in-out infinite;\n\n  > img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n}\n\n@keyframes breathe {\n  0%, 100% {\n    transform: scale(1) translateY(0);\n  }\n  50% {\n    transform: scale(1.05) translateY(-5px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imagesWrapper": `styles_imagesWrapper__IZO16`,
	"image": `styles_image__7tevc`,
	"imgWrap": `styles_imgWrap__wIWgN`,
	"breathe": `styles_breathe__6Qt7P`
};
export default ___CSS_LOADER_EXPORT___;
