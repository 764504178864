import React from "react";
import { CardImages } from "../../../utils/enums";
import ImageComponent from "../../Image/Image";
import styles from "./styles.module.scss";

const Cards = () => {
  const cardImages = [
    CardImages.ELITE,
    CardImages.LEGENDARY,
    CardImages.DIVINE,
    CardImages.RARE,
    CardImages.COMMON,
  ];

  return (
    <div className={styles.imagesWrapper}>
      {cardImages.map((image, index) => (
        <div key={index} className={styles.image}>
          <div className={styles.imgWrap}>
            <ImageComponent
              mainSrc={image.MAIN}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Cards;
