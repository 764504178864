// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_imageContainer__PmjhF {
  position: relative;
  width: 100%;
  height: 100%;
}

.styles_image__vIMaz {
  width: 100%;
  height: 100%;
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/components/Image/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACF","sourcesContent":[".imageContainer {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.image {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": `styles_imageContainer__PmjhF`,
	"image": `styles_image__vIMaz`
};
export default ___CSS_LOADER_EXPORT___;
