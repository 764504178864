import React, { useState, useEffect, forwardRef } from "react";
import styles from "./styles.module.scss";

const ImageComponent = forwardRef(
  (
    {
      mainSrc,
      previewSrc = null,
      width = "",
      height = "",
      wrapperClassName = "",
      imageClassName = "",
      alt = "",
    },
    ref,
  ) => {
    const [mainLoaded, setMainLoaded] = useState(false);
    const [previewLoaded, setPreviewLoaded] = useState(false);

    useEffect(() => {
      const mainImage = new window.Image();
      const previewImage = previewSrc ? new window.Image() : null;

      mainImage.src = `https://s3.pathgame.app/public/${mainSrc}`;
      if (previewImage) {
        previewImage.src = `https://s3.pathgame.app/public/${previewSrc}`;
        previewImage.onload = () => setPreviewLoaded(true);
      }
      mainImage.onload = () => setMainLoaded(true);
    }, [mainSrc, previewSrc]);

    return (
      <div
        ref={ref}
        style={{ position: "relative", width, height }}
        className={wrapperClassName}
      >
        {!previewLoaded && !mainLoaded && <div style={{ width, height }} />}
        {previewLoaded && !mainLoaded && previewSrc && (
          <img
            src={`https://s3.pathgame.app/public/${previewSrc}`}
            alt={alt}
            className={`${styles.image} ${imageClassName}`}
          />
        )}
        {mainLoaded && (
          <img
            src={`https://s3.pathgame.app/public/${mainSrc}`}
            alt={alt}
            className={`${styles.image} ${imageClassName}`}
          />
        )}
      </div>
    );
  },
);

export default ImageComponent;
