import React from "react";
import styles from "./styles.module.scss";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { PiXLogoFill } from "react-icons/pi";

const SocialLinks = () => {
  return (
    <div className={styles.socialLinks}>
      <a
        href="https://www.facebook.com/people/The-Path/61563598798773/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook"
      >
        <FaFacebook size={30} />
      </a>
      <a
        href="https://x.com/thepath0"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Twitter"
      >
        <PiXLogoFill size={30} />
      </a>
      <a
        href="https://www.instagram.com/discoverthepath/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram"
      >
        <FaInstagram size={30} />
      </a>
      <a
        href="https://www.youtube.com/@discoverthepathgame"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="YouTube"
      >
        <FaYoutube size={30} />
      </a>
    </div>
  );
};

export default SocialLinks;
