import paths from "../pages/paths";
import Landing from "../pages/Landing/Landing";
import About from "../pages/About/About";
import Wiki from "../pages/Wiki/Wiki";

const routesConfig = [
  {
    path: paths.index,
    component: Landing,
  },
  {
    path: paths.about,
    component: About,
  },
  {
    path: paths.wiki,
    component: Wiki,
  },
];

export default routesConfig;
