// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[8].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[4]!../../index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_scrollToTop__uK6zD {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background: #f4e4bc;
  border: 2px solid #b59e60;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  color: #4a3b2c;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  padding: 0;
}
.styles_scrollToTop__uK6zD.styles_visible__yY-X0 {
  opacity: 0.8;
  visibility: visible;
  transform: translateY(0);
}
.styles_scrollToTop__uK6zD:hover {
  transform: translateY(-5px);
  opacity: 1;
  box-shadow: 0 5px 20px rgba(181, 158, 96, 0.5);
}
.styles_scrollToTop__uK6zD:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(181, 158, 96, 0.5);
}
.styles_scrollToTop__uK6zD svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .styles_scrollToTop__uK6zD {
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
  .styles_scrollToTop__uK6zD svg {
    width: 20px;
    height: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ScrollToTop/styles.module.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,aAAA;EACA,cAAA;EACA,uCAAA;EACA,yBAAA;EACA,UAAA;EACA,kBAAA;EACA,2BAAA;EACA,UAAA;AAAJ;AAEI;EACI,YAAA;EACA,mBAAA;EACA,wBAAA;AAAR;AAGI;EACI,2BAAA;EACA,UAAA;EACA,8CAAA;AADR;AAII;EACI,aAAA;EACA,6CAAA;AAFR;AAKI;EACI,WAAA;EACA,YAAA;AAHR;;AAOA;EACI;IACI,YAAA;IACA,WAAA;IACA,WAAA;IACA,YAAA;EAJN;EAMM;IACI,WAAA;IACA,YAAA;EAJV;AACF","sourcesContent":["@import \"../../index.css\";\n\n.scrollToTop {\n    position: fixed;\n    bottom: 30px;\n    right: 30px;\n    width: 50px;\n    height: 50px;\n    background: #f4e4bc;\n    border: 2px solid #b59e60;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    z-index: 1000;\n    color: #4a3b2c;\n    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);\n    transition: all 0.3s ease;\n    opacity: 0;\n    visibility: hidden;\n    transform: translateY(20px);\n    padding: 0;\n\n    &.visible {\n        opacity: 0.8;\n        visibility: visible;\n        transform: translateY(0);\n    }\n\n    &:hover {\n        transform: translateY(-5px);\n        opacity: 1;\n        box-shadow: 0 5px 20px rgba(181, 158, 96, 0.5);\n    }\n\n    &:focus {\n        outline: none;\n        box-shadow: 0 0 0 3px rgba(181, 158, 96, 0.5);\n    }\n\n    svg {\n        width: 24px;\n        height: 24px;\n    }\n}\n\n@media (max-width: 768px) {\n    .scrollToTop {\n        bottom: 20px;\n        right: 20px;\n        width: 40px;\n        height: 40px;\n\n        svg {\n            width: 20px;\n            height: 20px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollToTop": `styles_scrollToTop__uK6zD`,
	"visible": `styles_visible__yY-X0`
};
export default ___CSS_LOADER_EXPORT___;
