// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes styles_blink__yBfJ8 {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  60% {
    transform: scale(0.9);
  }
}
.styles_backButton__ThglY {
  position: fixed;
  z-index: 10;
  top: 10px;
  left: 10px;
  background: #f4e4bc;
  border: 2px solid #b59e60;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a3b2c;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: styles_blink__yBfJ8 3s infinite;
  transition: transform 0.3s;
  width: 40px;
  height: 40px;
}
.styles_backButton__ThglY:hover {
  transform: scale(1.1);
}
.styles_backButton__ThglY:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/AboutComponents/BackButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE;IAEE,UAAA;EAAF;EAEA;IACE,YAAA;EAAF;EAEA;IACE,qBAAA;EAAF;AACF;AAGA;EACE,eAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,uCAAA;EACA,0CAAA;EACA,0BAAA;EACA,WAAA;EACA,YAAA;AADF;AAGE;EACE,qBAAA;AADJ;AAIE;EACE,aAAA;AAFJ","sourcesContent":["@keyframes blink {\n  0%,\n  100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.4;\n  }\n  60% {\n    transform: scale(0.9);\n  }\n}\n\n.backButton {\n  position: fixed;\n  z-index: 10;\n  top: 10px;\n  left: 10px;\n  background: #f4e4bc;\n  border: 2px solid #b59e60;\n  border-radius: 10px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #4a3b2c;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n  animation: blink 3s infinite;\n  transition: transform 0.3s;\n  width: 40px;\n  height: 40px;\n\n  &:hover {\n    transform: scale(1.1);\n  }\n\n  &:focus {\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": `styles_backButton__ThglY`,
	"blink": `styles_blink__yBfJ8`
};
export default ___CSS_LOADER_EXPORT___;
