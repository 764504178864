export const aboutGameText =
    "Step into the epic world of The Path, a groundbreaking MMORPG that combines NFT heroes with dynamic gacha mechanics. Built directly into Telegram, The Path offers players the chance to embark on thrilling quests, engage in strategic PvP battles, and earn real cryptocurrency rewards. Every hero is a unique NFT, providing endless opportunities for customization, progression, and trade. With a player-driven economy, seasonal gameplay, and blockchain integration, The Path transforms your in-game efforts into tangible assets. Whether you’re leveling heroes, minting NFTs, or climbing the leaderboards, The Path promises an immersive journey filled with challenges, rewards, and unforgettable adventures. Join us today and redefine your gaming experience!";

export const CardImages = {
  COMMON: {
    MAIN: "cards/back/common_preview.webp",
  },
  DIVINE: {
    MAIN: "cards/back/divine_preview.webp",
  },
  ELITE: {
    MAIN: "cards/back/elite_preview.webp",
  },
  LEGENDARY: {
    MAIN: "cards/back/legendary_preview.webp",
  },
  RARE: {
    MAIN: "cards/back/rare_preview.webp",

  },
};

export const NavbarImages = {
  HOME: {
    MAIN: "navbar/home.webp",
  },
  QUESTS: {
    MAIN: "navbar/quests.webp",
  },
  RATING: {
    MAIN: "navbar/rating.webp",
  },
  SHOP: {
    MAIN: "navbar/shop.webp",
  },
  HEROES: {
    MAIN: "navbar/heroes.webp",
  },
};

export const ClassImages = {
  ASSASSIN: {
    MAIN: "classes/assassin.webp",
  },
  BARD: {
    MAIN: "classes/bard.webp",
  },
  DEFENDER: {
    MAIN: "classes/defender.webp",
  },
  MAG: {
    MAIN: "classes/mag.webp",
  },
  WARRIOR: {
    MAIN: "classes/warrior.webp",
  },
};

export const AttributeImages = {
  AGILITY: {
    MAIN: "attributes/agility.webp",
  },
  CHARISMA: {
    MAIN: "attributes/charisma.webp",
  },
  ENDURANCE: {
    MAIN: "attributes/endurance.webp",
  },
  INT: {
    MAIN: "attributes/int.webp",
  },
  LUCK: {
    MAIN: "attributes/luck.webp",
  },
  STRENGTH: {
    MAIN: "attributes/strength.webp",
  },
};

export const SilhouetteImages = {
  SILHOUETTE_1: {
    main: "silhouette/1_preview.webp",
  },
  SILHOUETTE_2: {
    main: "silhouette/2_preview.webp",
  },
  SILHOUETTE_3: {
    main: "silhouette/3_preview.webp",
  },
  SILHOUETTE_4: {
    main: "silhouette/4_preview.webp",
  },
  SILHOUETTE_5: {
    main: "silhouette/5_preview.webp",
  },
};

export const mobileBackground =
  "https://s3.pathgame.app/public/layout/background_mobile.webp";
export const desktopBackground =
  "https://s3.pathgame.app/public/layout/background_mobile.webp";

const prefixUrl = "https://s3.pathgame.app/public/";

export const attributesData = [
  {
    name: "Strength",
    description: `Great for Warrior
      <img src="${prefixUrl}${ClassImages.WARRIOR.MAIN}" alt="warrior icon" width="20px" height="20px"/>`,
    icon: AttributeImages.STRENGTH.MAIN,
    classIcons: [ClassImages.WARRIOR.MAIN],
  },
  {
    name: "Agility",
    description: `Ideal for Assasin
      <img src="${prefixUrl}${ClassImages.ASSASSIN.MAIN}" alt="assassin icon" width="20px" height="20px"/>`,
    icon: AttributeImages.AGILITY.MAIN,
    classIcons: [ClassImages.ASSASSIN.MAIN],
  },
  {
    name: "Intelligence",
    description: `Crucial for Mage 
      <img src="${prefixUrl}${ClassImages.MAG.MAIN}" alt="mage icon" width="20px" height="20px"/>`,
    icon: AttributeImages.INT.MAIN,
    classIcons: [ClassImages.MAG.MAIN],
  },
  {
    name: "Endurance",
    description: `Beneficial for Defender
      <img src="${prefixUrl}${ClassImages.DEFENDER.MAIN}" alt="defender icon" width="20px" height="20px"/>`,
    icon: AttributeImages.ENDURANCE.MAIN,
    classIcons: [ClassImages.DEFENDER.MAIN],
  },
  {
    name: "Charisma",
    description: `Vital for Bard
      <img src="${prefixUrl}${ClassImages.BARD.MAIN}" alt="bard icon" width="20px" height="20px"/>`,
    icon: AttributeImages.CHARISMA.MAIN,
    classIcons: [ClassImages.BARD.MAIN, ClassImages.ASSASSIN.MAIN],
  },
  {
    name: "Luck",
    description: `Increases chances of finding rare items and improves overall fortune in various tasks.`,
    icon: AttributeImages.LUCK.MAIN,
    classIcons: [],
  },
];

export const apiUrl = process.env.REACT_APP_API_URL;
