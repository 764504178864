import React from "react";
import styles from "./styles.module.scss";
import { AboutIcon, PlayIcon } from "../../assets/icons";

const PlayButton = ({ onClick, isAbout = false }) => {
  return (
    <div className={styles.button} onClick={onClick}>
      {isAbout ? <AboutIcon /> : <PlayIcon />}
      &nbsp;
    </div>
  );
};

export default PlayButton;
