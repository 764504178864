import { useEffect, useState } from "react";
import { SilhouetteImages } from "../utils/enums";
import styles from "../components/AboutComponents/AboutTextCard/styles.module.scss";

const images = Object.values(SilhouetteImages);

const useImageSwitcher = (startIndex = 0, id, cardRef, imgRef) => {
  const [currentImage, setCurrentImage] = useState(images[startIndex]);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    let currentIndex = startIndex;

    const interval = setInterval(() => {
      setVisible(false);
      setTimeout(() => {
        currentIndex = (currentIndex + 1) % images.length;
        setCurrentImage(images[currentIndex]);
        setVisible(true);
      }, 1000); // Time for fade-out transition
    }, 5000);

    return () => clearInterval(interval);
  }, [startIndex]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.visible);
            if (id) {
              window.history.replaceState(null, "", `#${id}`);
            }
          } else {
            entry.target.classList.remove(styles.visible);
          }
        });
      },
      { threshold: 0.1 },
    );

    const cardCurrent = cardRef.current;
    const imgCurrent = imgRef.current;

    if (cardCurrent) {
      observer.observe(cardCurrent);
    }
    if (imgCurrent) {
      observer.observe(imgCurrent);
    }

    return () => {
      if (cardCurrent) {
        observer.unobserve(cardCurrent);
      }
      if (imgCurrent) {
        observer.unobserve(imgCurrent);
      }
    };
  }, [cardRef, id, imgRef]);

  return { currentImage, visible };
};

export default useImageSwitcher;
