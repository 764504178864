import React from "react";
import { DescriptionIcon, LogoTextIcon } from "../../assets/icons";
import BackButton from "../../components/AboutComponents/BackButton/BackButton";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import styles from "./styles.module.scss";
const PhaseFoundationIcon = () => (
  <svg className={styles.phaseIcon} viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 22h20v-2H2v2zm2-4h16v-2H4v2zm-2-6l10-5 10 5v-2l-10-5-10 5v2z" fill="#8b4513"/>
  </svg>
);

const PhaseWeb3Icon = () => (
  <svg className={styles.phaseIcon} viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" stroke="#8b4513" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const PhasePvPIcon = () => (
  <svg className={styles.phaseIcon} viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5 17.5L19 14.5L14.5 11.5M9.5 11.5L5 14.5L9.5 17.5M12 10L12 19" stroke="#8b4513" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 7a2 2 0 100-4 2 2 0 000 4z" fill="#8b4513"/>
  </svg>
);

const PhaseMarketplaceIcon = () => (
  <svg className={styles.phaseIcon} viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 6h18l-3 10H6L3 6zm6 12h6m-3-6v6" stroke="#8b4513" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const PhaseGlobalIcon = () => (
  <svg className={styles.phaseIcon} viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9" stroke="#8b4513" strokeWidth="2"/>
    <path d="M12 3a9 9 0 0 1 9 9M3 12a9 9 0 0 1 9-9m-9 9a9 9 0 0 0 9 9m9-9a9 9 0 0 1-9 9" stroke="#8b4513" strokeWidth="2"/>
    <path d="M7 12h10M12 7v10" stroke="#8b4513" strokeWidth="2"/>
  </svg>
);

const PhaseCraftingIcon = () => (
  <svg className={styles.phaseIcon} viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 3l8 4.5v9L12 21l-8-4.5v-9L12 3zm0 9l8-4.5M12 12v9M12 12L4 7.5" stroke="#8b4513" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const PhaseBeyondIcon = () => (
  <svg className={styles.phaseIcon} viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L5 12l7 4 7-4-7-10z" fill="#8b4513"/>
    <path d="M5 12l7 10 7-10M5 12l7 4m7-4l-7 4" stroke="#8b4513" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const RoadMap = () => {

  return (
    <div className={styles.roadmapWrapper}>
      <BackButton />
      <ScrollToTop />
      <div style={{ marginTop: 24 }}>
        <LogoTextIcon />
      </div>
      <div>
        <DescriptionIcon />
      </div>
      <div className={styles.content}>
        <div className={styles.roadmapItems}>
          <div className={`${styles.roadmapItem} ${styles.completed}`}>
            <div className={styles.phase}>
              <PhaseFoundationIcon />
              <span>Phase 1: Foundation & Initial Release (Q2–Q3 2024)</span>
            </div>
            <div className={styles.description}>
              <ul>
                <li><strong>Project Inception (June 2024)</strong>: Conceptualization and early prototyping.</li>
                <li><strong>Game Launch (July 29, 2024)</strong>: Hero collection, leveling, and quests introduced.</li>
                <li><strong>Specialized Hero Items & Treasures System (August 2024)</strong>: Introduction of hero items and treasures.</li>
                <li><strong>Hero Skills Update (September 2024)</strong>: 9 abilities per hero, foundation for Ultimates.</li>
                <li><strong>Quests & Adventure System (Sep–Oct 2024)</strong>: Core progression mechanics established.</li>
                <li><strong>Daily & Weekly Giveaways System (October 2024)</strong>: Implementation of automated daily and weekly giveaway system with reward distributions.</li>
              </ul>
            </div>
          </div>
          
          <div className={`${styles.roadmapItem} ${styles.completed}`}>
            <div className={styles.phase}>
              <PhaseWeb3Icon />
              <span>Phase 2: Gameplay & Multiplayer Development (Q4 2024–Q1 2025)</span>
            </div>
            <div className={styles.description}>
              <ul>
                <li><strong>NFT Hero Collection Release (November 2024)</strong>: First collection of unique NFT heroes launched.</li>
                <li><strong>Cauldron System (November 2024)</strong>: Strongest heroes can be summoned from the Cauldron.</li>
                <li><strong>Zephyr, In-Game Guide (November 2024)</strong>: Enhanced player onboarding.</li>
                <li><strong>Event System & Winter's Embrace (February 2025)</strong>: Launch of the event system framework with the Winter's Embrace seasonal event.</li>
                <li><strong>Withdrawal System (March 2025)</strong>: Crypto withdrawals implemented; players can claim rewards.</li>
                <li><strong>Tiger Heroes Collaboration (March - April 2025)</strong>: First month-long partnership event featuring 5 new Tiger heroes.</li>
              </ul>
            </div>
          </div>
          
          <div className={styles.roadmapItem}>
            <div className={styles.phase}>
              <PhasePvPIcon />
              <span>Phase 3: PvP Development (Q2 2025)</span>
            </div>
            <div className={styles.description}>
              <ul>
                <li className={styles.currentPhase}><strong>PvP Alpha Release</strong>: Initial combat system implementation.</li>
                <li><strong>PvE Dungeons & Multiplayer</strong>: Cooperative dungeon system with multiplayer support.</li>
                <li><strong>PvP Alpha MMR System</strong>: Basic matchmaking and ranking mechanics.</li>
                <li><strong>Guild System Implementation</strong>:
                  <ul className={styles.nestedList}>
                    <li>Guild creation and management</li>
                    <li>Guild rewards and progression</li>
                  </ul>
                </li>
                <li><strong>PvP Beta Enhancements</strong>:
                  <ul className={styles.nestedList}>
                    <li>Advanced movement mechanics</li>
                    <li>Consumable items system</li>
                    <li>Combat mechanics improvements</li>
                  </ul>
                </li>
                <li><strong>PvP Beta MMR Evolution</strong>:
                  <ul className={styles.nestedList}>
                    <li>Seasonal rankings</li>
                    <li>Achievement badges</li>
                    <li>Competitive rewards</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          
          <div className={styles.roadmapItem}>
            <div className={styles.phase}>
              <PhaseWeb3Icon />
              <span>Phase 4: Web3 Integration & Season 2 (Q3 2025)</span>
            </div>
            <div className={styles.description}>
              <ul>
                <li><strong>$PATH Token Launch</strong>: Introduction of TON-based governance token.</li>
                <li><strong>Web3 Game Features</strong>:
                  <ul className={styles.nestedList}>
                    <li>Token economy implementation</li>
                    <li>Play-to-earn mechanics</li>              
                    <li>Staking and rewards system</li>
                  </ul>
                </li>
                <li><strong>Season 2 Launch</strong>:
                  <ul className={styles.nestedList}>
                    <li>New gameplay features</li>
                    <li>Enhanced reward mechanics</li>
                    <li>Competitive season start</li>
                    <li>Web3 achievements system</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          
          <div className={styles.roadmapItem}>
            <div className={styles.phase}>
              <PhaseMarketplaceIcon />
              <span>Phase 5: Marketplace & Global Update (Q4 2025)</span>
            </div>
            <div className={styles.description}>
              <ul>
                <li><strong>Hero & Item Marketplace</strong>: Player-driven economy for trading heroes and items.</li>
                <li><strong>Global Territory System</strong>:
                  <ul className={styles.nestedList}>
                    <li>Guild territory allocation on global map</li>
                    <li>Territory capture and control mechanics</li>
                    <li>Boss spawns on controlled territories</li>
                    <li>Guild wars: Massive team-based PvP battles</li>
                    <li>Territory-based rewards and resources</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          
          <div className={styles.roadmapItem}>
            <div className={styles.phase}>
              <PhaseGlobalIcon />
              <span>Phase 6: Land & Resource Expansion (Q1 2026)</span>
            </div>
            <div className={styles.description}>
              <ul>
                <li><strong>Land System Expansion</strong>:
                  <ul className={styles.nestedList}>
                    <li>Resource gathering mechanics</li>
                    <li>Advanced crafting system</li>
                    <li>Territory development</li>
                    <li>Resource trading</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          
          <div className={styles.roadmapItem}>
            <div className={styles.phase}>
              <PhaseBeyondIcon />
              <span>Beyond (Q2 2026+)</span>
            </div>
            <div className={styles.description}>
              <ul>
                <li><strong>Guild Wars & Clan Battles</strong>: Massive guild vs guild conflicts and cooperative PvE events.</li>
                <li><strong>Cross-Chain Integration & Advanced Web3 Features</strong>: Explore interoperability across blockchain ecosystems, expanding $PATH token utility.</li>
                <li><strong>Continuous Content & Seasonal Updates</strong>: Regular introduction of new heroes, quests, events, and features.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.disclaimer}>
          <p>* Please note: All dates, features, and milestones presented in this roadmap are subject to change based on development progress, market conditions, and community feedback.</p>
        </div>
      </div>
    </div>
  );
};

export default RoadMap; 