// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GameInterfaceOverview_navBarSection__92UL9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.GameInterfaceOverview_navBarSection__92UL9 > h3 {
  margin-bottom: unset;
}`, "",{"version":3,"sources":["webpack://./src/components/AboutComponents/GameInterfaceOverview/GameInterfaceOverview.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AACF;AAAE;EACE,oBAAA;AAEJ","sourcesContent":[".navBarSection {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 24px;\n  > h3 {\n    margin-bottom: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBarSection": `GameInterfaceOverview_navBarSection__92UL9`
};
export default ___CSS_LOADER_EXPORT___;
