// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#index_root__YSWHP, html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

html, body {
  background-color: #282c34;
}

body {
  overflow: hidden;
  display: flex;
  flex: 1 1;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: inherit;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
}

a:hover {
  text-decoration: underline;
}

* {
  box-sizing: border-box;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  width: 0;
  height: 0;
}

iframe {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,SAAA;EACA,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;AADF;;AAIA;EACE,+EAAA;AADF;;AAKA;EACE,qBAAA;EACA,cAAA;EACA,yBAAA;EAGA,iBAAA;EACA,aAAA;AAFF;;AAKA;EACE,0BAAA;AAFF;;AAKA;EACE,sBAAA;EAMA,qBAAA;AAPF;AAEE;EACE,QAAA;EACA,SAAA;AAAJ;;AAMA;EAEE,aAAA;AAJF","sourcesContent":["#root, html {\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\nhtml, body {\n  background-color: #282c34;\n}\n\nbody {\n  overflow: hidden;\n  display: flex;\n  flex: 1;\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n  sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n  monospace;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  outline: none;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\n* {\n  box-sizing: border-box;\n  &::-webkit-scrollbar {\n    width: 0;\n    height: 0;\n  }\n\n  scrollbar-width: none;\n}\n\niframe\n{\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `index_root__YSWHP`
};
export default ___CSS_LOADER_EXPORT___;
