import React from "react";
import AboutTextCard from "../AboutTextCard/AboutTextCard";
import GameInterfaceOverview from "../GameInterfaceOverview/GameInterfaceOverview";

const InterfaceSection = () => (
  <AboutTextCard
    id="interface"
    title={"Game Interface Overview"}
    isLeft
    startIndex={1}
  >
    <GameInterfaceOverview />
  </AboutTextCard>
);

export default InterfaceSection;
