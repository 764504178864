// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_socialLinks__dNsG0 {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.styles_socialLinks__dNsG0 a {
  color: white;
  text-decoration: none;
}
.styles_socialLinks__dNsG0 a:hover {
  color: black;
}

.styles_isGame__bgY8N {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}`, "",{"version":3,"sources":["webpack://./src/components/SocialLinks/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,wCAAA;AACF;AACE;EACE,YAAA;EACA,qBAAA;AACJ;AACI;EACE,YAAA;AACN;;AAIA;EACE,eAAA;EACA,YAAA;EACA,SAAA;EACA,2BAAA;AADF","sourcesContent":[".socialLinks {\n  display: flex;\n  gap: 20px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n  background: rgba(0, 0, 0, 0.4);\n  padding: 12px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n\n  a {\n    color: white;\n    text-decoration: none;\n\n    &:hover {\n      color: black;\n    }\n  }\n}\n\n.isGame {\n  position: fixed;\n  bottom: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"socialLinks": `styles_socialLinks__dNsG0`,
	"isGame": `styles_isGame__bgY8N`
};
export default ___CSS_LOADER_EXPORT___;
