import React from "react";
import AboutTextCard from "../AboutTextCard/AboutTextCard";
import RarityText from "../RarityText/RarityText";
import Cards from "../Cards/Cards";

const RaritiesSection = () => (
  <AboutTextCard id="rarities" title={"Rarities"} startIndex={2}>
    <RarityText />
    <Cards />
  </AboutTextCard>
);

export default RaritiesSection;
