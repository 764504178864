// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__VPntA {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  position: relative;
  padding: 0 8px;
  scrollbar-width: none;
}

.styles_wrapper__VPntA::-webkit-scrollbar {
  display: none;
}

.styles_text__O6BYs {
  background: #f4e4bc;
  border: 2px solid #b59e60;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 15px;
}

.styles_backButtonWrapper__14Yod {
  position: absolute;
  z-index: 10;
  top: 10px;
  left: 10px;
  background: #f4e4bc;
  border: 2px solid #b59e60;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a3b2c;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s;
  width: 40px;
  height: 40px;
}
.styles_backButtonWrapper__14Yod svg path {
  stroke: #4a3b2c;
}
.styles_backButtonWrapper__14Yod:hover {
  transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/pages/About/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,cAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,mBAAA;EACA,yBAAA;EACA,uCAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,uCAAA;EACA,0BAAA;EACA,WAAA;EACA,YAAA;AACF;AAEI;EACE,eAAA;AAAN;AAIE;EACE,qBAAA;AAFJ","sourcesContent":[".wrapper {\n  width: 100%;\n  max-width: 1100px;\n  margin: 0 auto;\n  height: 100%;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  align-items: center;\n  position: relative;\n  padding: 0 8px;\n  scrollbar-width: none;\n}\n\n.wrapper::-webkit-scrollbar {\n  display: none;\n}\n\n.text {\n  background: #f4e4bc;\n  border: 2px solid #b59e60;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n  padding: 20px;\n  border-radius: 15px;\n}\n\n.backButtonWrapper {\n  position: absolute;\n  z-index: 10;\n  top: 10px;\n  left: 10px;\n  background: #f4e4bc;\n  border: 2px solid #b59e60;\n  border-radius: 10px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #4a3b2c;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n  transition: transform 0.3s;\n  width: 40px;\n  height: 40px;\n\n  svg {\n    path {\n      stroke: #4a3b2c;\n    }\n  }\n\n  &:hover {\n    transform: scale(1.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__VPntA`,
	"text": `styles_text__O6BYs`,
	"backButtonWrapper": `styles_backButtonWrapper__14Yod`
};
export default ___CSS_LOADER_EXPORT___;
