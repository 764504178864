import React from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import paths from "../../../pages/paths";
import { BackArrow } from "../../../assets/icons";

const BackButton = () => {
  const navigate = useNavigate();

  const handleGoToIndex = () => {
    navigate(paths.index);
  };

  return (
    <button className={styles.backButton} onClick={handleGoToIndex}>
      <BackArrow />
    </button>
  );
};

export default BackButton;
