import React from "react";
import AboutTitle from "../../components/AboutComponents/AboutTitle/AboutTitle";
import AttributesSection from "../../components/AboutComponents/Sections/AttributesSection";
import ClassesSection from "../../components/AboutComponents/Sections/ClassesSection";
import DiscoverSection from "../../components/AboutComponents/Sections/DiscoverSection";
import InterfaceSection from "../../components/AboutComponents/Sections/InterfaceSection";
import RaritiesSection from "../../components/AboutComponents/Sections/RaritiesSection";
import styles from "./styles.module.scss";

const About = () => {
  // useEffect(() => {
  //   const hash = window.location.hash;
  //   if (hash) {
  //     console.log(hash);
  //     const element = document.querySelector(hash);
  //     if (element) {
  //       setTimeout(() => {
  //         element.scrollIntoView({ behavior: "smooth" });
  //       }, 100);
  //     }
  //   }
  // }, []);

  return (
    <div className={styles.wrapper}>
      <AboutTitle />
      <DiscoverSection />
      <InterfaceSection />
      <RaritiesSection />
      <ClassesSection />
      <AttributesSection />
    </div>
  );
};

export default About;
