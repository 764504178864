// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__5pdwe {
  background-color: #8b4513;
  border: 2px solid #5c3317;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 160px;
  margin: 8px 0;
}
.styles_button__5pdwe:hover {
  background-color: #a0522d;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4);
  transform: translateY(-2px);
}
.styles_button__5pdwe:active {
  background-color: #5c3317;
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.styles_buttonText__bpb1Z {
  color: #f4e4bc;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: "Papyrus", sans-serif;
}

@media (max-width: 768px) {
  .styles_button__5pdwe {
    min-width: 140px;
    padding: 8px 16px;
  }
  .styles_buttonText__bpb1Z {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PlayButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,wCAAA;EACA,2CAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,gBAAA;EACA,aAAA;AACF;AACE;EACE,yBAAA;EACA,wCAAA;EACA,2BAAA;AACJ;AAEE;EACE,yBAAA;EACA,0BAAA;EACA,wCAAA;AAAJ;;AAIA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,kCAAA;AADF;;AAIA;EACE;IACE,gBAAA;IACA,iBAAA;EADF;EAIA;IACE,eAAA;EAFF;AACF","sourcesContent":[".button {\n  background-color: #8b4513;\n  border: 2px solid #5c3317;\n  border-radius: 10px;\n  padding: 10px 20px;\n  cursor: pointer;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);\n  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);\n  transition: all 0.3s ease;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n  min-width: 160px;\n  margin: 8px 0;\n\n  &:hover {\n    background-color: #a0522d;\n    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4);\n    transform: translateY(-2px);\n  }\n\n  &:active {\n    background-color: #5c3317;\n    transform: translateY(2px);\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n  }\n}\n\n.buttonText {\n  color: #f4e4bc;\n  font-size: 18px;\n  font-weight: 600;\n  letter-spacing: 0.5px;\n  font-family: \"Papyrus\", sans-serif;\n}\n\n@media (max-width: 768px) {\n  .button {\n    min-width: 140px;\n    padding: 8px 16px;\n  }\n\n  .buttonText {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__5pdwe`,
	"buttonText": `styles_buttonText__bpb1Z`
};
export default ___CSS_LOADER_EXPORT___;
