import React from "react";
import { aboutGameText } from "../../../utils/enums";
import AboutTextCard from "../AboutTextCard/AboutTextCard";

const DiscoverSection = () => (
  <AboutTextCard id="discover" title={"Discover The Path"} startIndex={0}>
    {aboutGameText}
  </AboutTextCard>
);

export default DiscoverSection;
