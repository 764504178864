import React from "react";
import styles from "./RarityText.module.scss";

const RarityText = () => (
  <div style={{ marginBottom: "2rem" }}>
    In our game, there are five types of card rarities:
    <span className={styles.common}> Common</span>,
    <span className={styles.rare}> Rare</span>,
    <span className={styles.elite}> Elite</span>,
    <span className={styles.legendary}> Legendary</span>, and
    <span className={styles.divine}> Divine</span>. As rarity increases, the
    drop rate decreases.
    <span className={styles.common}> Common </span> cards are frequent and
    provide minimal stat boosts.
    <span className={styles.rare}> Rare </span> cards increase stats more than
    common ones.
    <span className={styles.elite}> Elite </span> cards further enhance
    character stats significantly.
    <span className={styles.legendary}> Legendary </span> cards, being very
    rare, offer substantial stat boosts.
    <span className={styles.divine}> Divine </span> cards, the rarest and most
    valuable, provide the maximum boost and the widest range of stats,
    significantly enhancing the character's abilities.
  </div>
);

export default RarityText;
