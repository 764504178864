import React from "react";
import { attributesData } from "../../../utils/enums";
import ImageComponent from "../../Image/Image";
import styles from "./CharacterAttributes.module.scss";

const AttributeItem = ({ name, description, icon }) => (
  <div className={styles.attributeItem}>
    <ImageComponent
      mainSrc={icon}
      alt={`${name} icon`}
      wrapperClassName={styles.iconWrapper}
      imageClassName={styles.icon}
      width="30px"
      height="30px"
    />
    <div className={styles.textWrapper}>
      <strong>{name}</strong>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  </div>
);

const CharacterAttributes = () => (
  <div className={styles.attributesText}>
    <span>Each character is assigned starting attributes.</span>
    <span>There are 5:</span>
    <div className={styles.attributeInfo}>
      {attributesData.map((attribute, index) => (
        <AttributeItem
          key={index}
          name={attribute.name}
          description={attribute.description}
          icon={attribute.icon}
        />
      ))}
    </div>
  </div>
);

export default CharacterAttributes;
