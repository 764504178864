import React from "react";
import styles from "./LootTable.module.scss";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const LootTable = ({ table, isExpanded, onToggle }) => {
  return (
    <div className={styles.tableContainer}>
      <div
        className={styles.header}
        onClick={onToggle}
        role="button"
        tabIndex={0}
      >
        <h2 className={styles.text}>{table.name}</h2>
        <div className={styles.icon}>
          {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {isExpanded && (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Class</th>
              <th>Chance</th>
            </tr>
          </thead>
          <tbody>
            {table.items.map((item, idx) => (
              <tr key={idx}>
                <td>{item.name}</td>
                <td>{item.type}</td>
                <td>{item.class || "N/A"}</td>
                <td>
                  {item.receiving_chance
                    ? `${parseFloat((item.receiving_chance * 100).toPrecision(12))}%`
                    : `${parseFloat((item.receiving_chance || 0).toFixed(2))}%`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default LootTable;
