import React from "react";
import styles from "./styles.module.scss";
import { useMediaQuery } from "react-responsive";
import { desktopBackground, mobileBackground } from "../../utils/enums";

const DefaultLayout = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <div
        className={styles.landingWrapper}
        style={{
          backgroundImage: `url(${isMobile ? mobileBackground : desktopBackground})`,
        }}
      >
        {children}
      </div>
    </>
  );
};

export default DefaultLayout;
