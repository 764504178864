import React from "react";
import styles from "./styles.module.scss";

const PlayButton = ({ onClick, title = "Play" }) => {
  return (
    <div className={styles.button} onClick={onClick}>
      <span className={styles.buttonText}>{title}</span>
    </div>
  );
};

export default PlayButton;
